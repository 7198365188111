// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-about-js": () => import("./../../../src/pages/app/about.js" /* webpackChunkName: "component---src-pages-app-about-js" */),
  "component---src-pages-app-brands-js": () => import("./../../../src/pages/app/brands.js" /* webpackChunkName: "component---src-pages-app-brands-js" */),
  "component---src-pages-app-careers-js": () => import("./../../../src/pages/app/careers.js" /* webpackChunkName: "component---src-pages-app-careers-js" */),
  "component---src-pages-app-cookies-js": () => import("./../../../src/pages/app/cookies.js" /* webpackChunkName: "component---src-pages-app-cookies-js" */),
  "component---src-pages-app-home-js": () => import("./../../../src/pages/app/home.js" /* webpackChunkName: "component---src-pages-app-home-js" */),
  "component---src-pages-app-partners-js": () => import("./../../../src/pages/app/partners.js" /* webpackChunkName: "component---src-pages-app-partners-js" */),
  "component---src-pages-app-patrons-js": () => import("./../../../src/pages/app/patrons.js" /* webpackChunkName: "component---src-pages-app-patrons-js" */),
  "component---src-pages-app-privacy-policy-js": () => import("./../../../src/pages/app/privacy-policy.js" /* webpackChunkName: "component---src-pages-app-privacy-policy-js" */),
  "component---src-pages-app-risks-js": () => import("./../../../src/pages/app/risks.js" /* webpackChunkName: "component---src-pages-app-risks-js" */),
  "component---src-pages-app-terms-of-use-js": () => import("./../../../src/pages/app/terms-of-use.js" /* webpackChunkName: "component---src-pages-app-terms-of-use-js" */),
  "component---src-pages-app-thank-you-js": () => import("./../../../src/pages/app/thank-you.js" /* webpackChunkName: "component---src-pages-app-thank-you-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-patrons-js": () => import("./../../../src/pages/patrons.js" /* webpackChunkName: "component---src-pages-patrons-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-risks-js": () => import("./../../../src/pages/risks.js" /* webpackChunkName: "component---src-pages-risks-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-co-branded-partner-js": () => import("./../../../src/templates/coBrandedPartner.js" /* webpackChunkName: "component---src-templates-co-branded-partner-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

